@keyframes typewriter {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blinkTextCursor {
    from { border-right-color: #40ac70; }
    to { border-right-color: transparent; }
  }
  
  .typewriter h1 {
    overflow: hidden;
    border-right: .15em solid #40ac70; 
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: normal; 
    animation: 
      typewriter 3s steps(21) 1s 1 normal both,
      blinkTextCursor 600ms steps(30) infinite normal;
  }
  
 .typed-cursor {
  color: #40ac70;
 }

 
 @keyframes flyIn {
  from {
      transform: translateY(-50px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.promo-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  animation: flyIn 1s ease-out;
}

.promo-text {
  text-align: center;
  font-size: 0.875rem; 
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  background-color: #f9fafb;
  border: 1px dotted #d1d5db; 
  border-radius: 9999px;
}

.promo-text .font-bold {
  color: #40ac70; 
}


